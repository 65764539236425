<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header py-0">
            <vb-headers-card-header-4 :data="headerData" />
          </div>
          <div class="card-body">
            <vb-controls-button-1
              :data="{ title: 'Ajouter a l\équipe', icon: 'fe fe-plus-circle', type: 'primary' }"
              @click="addMember"
            />
            <OrganigrameTable :data="dataSource" :loading="tableLoading" />
          </div>
        </div>
      </div>
    </div>
    <addMemberForm
      :visible="visible"
      @memberAdded="memberAdded"
      @cancel="
        () => {
          visible = false
        }
      "
    />
  </div>
</template>

<script>
import VbHeadersCardHeader4 from '@/@vb/widgets/Headers/CardHeader4'
import OrganigrameTable from '@/components/widgets/TablesAntd/organigrame'
import VbControlsButton1 from '@/@vb/widgets/Controls/Button'
import addMemberForm from '@/components/widgets/Forms/addMemberForm'
import { ref } from 'vue'
import apiClient from '@/services/axios'

export default {
  name: 'VbComptes',
  components: {
    VbHeadersCardHeader4,
    OrganigrameTable,
    VbControlsButton1,
    addMemberForm,
  },
  setup() {
    const visible = ref(false)
    const tableLoading = ref(true)
    const dataSource = ref([])
    const headerData = ref({
      title: 'Gestion de l\'organigrame',
      icon: 'fe fe-users',
      description: 'Ajouter votre équipes',
      res: {},
    })
    apiClient
      .post('/organigrame/filter',{
          query:{}
      })
      .then((res) => {
        dataSource.value = res.data
      })
      .catch((err) => console.log(err))
      .finally(() => (tableLoading.value = false))
    const addMember = () => {
      visible.value = !visible.value
    }
    const memberAdded = (newVideo) => {
      dataSource.value.unshift(newVideo)
      headerData.value.res.total = +headerData.value.res.total + 1
      visible.value = false
    }
    return {
      headerData,
      dataSource,
      visible,
      tableLoading,
      addMember,
      memberAdded,
    }
  },
}
</script>
