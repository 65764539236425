<!-- eslint-disable -->
<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="dataSource" rowKey="_id" :loading="tableLoading">
      <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>
      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customRender="{ text, column }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              class="highlight"
              :key="i"
            >
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template #utilisateur="{ text }">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
        </template>
      </template>
      <template #avatar="{ text, record }">
        <a-avatar :src="baseUrl + text" :size="64" @click="changeAvatar(record)" />
      </template>
      <template #fullNameFR="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input
              v-model:value="editableData[record._id].fr.fullName"
              @pressEnter="save(record)"
            />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            <a :href="text" target="_blank">{{ text || ' ' }} </a>
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #postFR="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record._id].fr.post" @pressEnter="save(record)" />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #fullNameAR="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input
              v-model:value="editableData[record._id].ar.fullName"
              @pressEnter="save(record)"
            />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            <a :href="text" target="_blank">{{ text || ' ' }} </a>
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #postAR="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record._id].ar.post" @pressEnter="save(record)" />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #fullNameEN="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input
              v-model:value="editableData[record._id].en.fullName"
              @pressEnter="save(record)"
            />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            <a :href="text" target="_blank">{{ text || ' ' }} </a>
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #postEN="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record._id].en.post" @pressEnter="save(record)" />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #status="{ text }">
        <span
          :class="[
            text === 'inactive'
              ? 'font-size-12 badge badge-primary'
              : 'font-size-12 badge badge-success',
          ]"
          >{{ text === 'inactive' ? 'Inactive' : 'Active' }}</span
        >
      </template>
      <template #order="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input
              type="number"
              :min="1"
              :max="count"
              v-model:value="editableData[record._id].order"
              @pressEnter="changeOrder(record)"
            />
            <check-outlined class="editable-cell-icon-check" @click="changeOrder(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            <a-tag v-if="text" color="#2db7f5">{{ text }}</a-tag>
            <a-tag v-else color="warning">L'ordre n'est pas spécifiée</a-tag>
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #action="{ record }">
        <span>
          <a class="btn btn-sm btn-light" @click="suppPub(record)">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            {{ record.status == 'active' ? 'Désactiver' : 'Activer' }}
          </a>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visibleModal"
      title="Modifier l'avatar"
      :width="400"
      style="height: 100px"
      okText="Modifier"
      @ok="onSubmit"
      @cancel="cancel"
    >
      <a-form
        label-align="left"
        class="container"
        :rules="rules"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 12 }"
      >
        <div class="row">
          <div class="col md-6">
            <a-upload-dragger
              :file-list="fileList"
              name="file"
              :multiple="false"
              @change="handleChange"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :custom-request="
                () => {
                  return true
                }
              "
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">Click or drag file to area to upload</p>
              <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data
                or other band files
              </p>
            </a-upload-dragger>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, computed, reactive, watch } from 'vue'
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { mapState } from 'vuex'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
export default {
  computed: mapState(['user']),
  components: {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    SearchOutlined,
    LoadingOutlined,
    PlusOutlined,
  },
  props: {
    data: {
      type: Array,
    },
  },
  setup(props) {
    const visibleModal = ref(false)
    const fileList = ref([])
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const columns = [
      {
        title: 'Avatar',
        dataIndex: 'avatar',
        width: '10%',
        slots: {
          customRender: 'avatar',
        },
      },
      {
        title: 'Nom et prénom',
        dataIndex: 'fr.fullName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'fullNameFR',
        },
        onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'Poste',
        dataIndex: 'fr.post',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'postFR',
        },
        onFilter: (value, record) => record.post.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: '  الاسم و اللقب',
        dataIndex: 'ar.fullName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'fullNameAR',
        },
        onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'المهنة',
        dataIndex: 'ar.post',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'postAR',
        },
        onFilter: (value, record) => record.post.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'Fullname',
        dataIndex: 'en.fullName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'fullNameEN',
        },
        onFilter: (value, record) => record.fullName.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'Job',
        dataIndex: 'en.post',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'postEN',
        },
        onFilter: (value, record) => record.post.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'status',
        dataIndex: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
        slots: { customRender: 'status' },
      },
      {
        title: 'Ordre',
        dataIndex: 'order',
        sorter: (a, b) => a - b,
        slots: { customRender: 'order' },
      },
      {
        title: 'Action',
        slots: { customRender: 'action' },
      },
    ]
    const dataSource = ref(props.data)
    watch(
      () => props.data,
      (first, second) => {
        dataSource.value = first
      },
    )
    /* const store = useStore()
    const user = computed(() => store.getters['user/user'])*/
    const count = computed(() => dataSource.value.length)
    const editableData = reactive({})
    const searchText = ref('')
    const searchInput = ref()
    const activeRecord = ref()
    const state = reactive({
      searchText: '',
      searchedColumn: '',
    })

    const visible = ref(false)
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = (clearFilters) => {
      clearFilters()
      searchText.value = ''
    }

    const suppPub = (record) => {
      record.status = record.status == 'active' ? 'inactive' : 'active'
      ApiClient.patch('/organigrame/' + record._id, {
        data: { status: record.status },
      })
        .then(() => {
          Object.assign(
            dataSource.value.filter((item) => record._id === item._id),
            record,
          )

          message.success(`Video est ${record.status == 'inactive' ? 'désactivé' : 'activé'}`)
        })
        .catch((e) => {
          message.warning("Impossible d'activer/désactiver le contenue")
        })
    }
    const edit = (record) => {
      editableData[record._id] = cloneDeep(
        dataSource.value.filter((item) => record._id === item._id)[0],
      )
    }

    const save = (record) => {
      console.log(editableData[record._id])
      ApiClient.patch('/organigrame/' + record._id, {
        data: editableData[record._id],
      })
        .then(() => {
          Object.assign(
            dataSource.value.filter((item) => record._id === item._id)[0],
            editableData[record._id],
          )
          delete editableData[record._id]

          message.success(`Equipe modifié`)
        })
        .catch((e) => {
          message.warning('Impossible de modifier le contenue')
        })
    }
    const changeOrder = (record) => {
      if (record.order > count) message.error("l'ordre spécifié dépasse le nombre des membres")
      else {
        ApiClient.patch('/organigrame/' + record._id, {
          data: {
            order: editableData[record._id].order,
          },
        })
          .then(() => {
            Object.assign(
              dataSource.value.filter((item) => record._id === item._id)[0],
              editableData[record._id],
            )
            delete editableData[record._id]

            message.success(`Ordre modifier`)
          })
          .catch((e) => {
            message.warning('Impossible de modifier le contenue')
          })
      }
    }
    const changeAvatar = (record) => {
      activeRecord.value = record
      visibleModal.value = true
    }
    const onSubmit = () => {
      if (fileList.value.length > 0) {
        let formData = new FormData()
        console.log(activeRecord.value._id)
        formData.append('avatar', fileList.value[0], activeRecord.value._id)
        ApiClient.patch('/organigrame/files/' + activeRecord.value._id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
            console.log(dataSource.value)
            activeRecord.value.avatar = res.data.avatar
            console.log(res.data.avatar)

            Object.assign(
              dataSource.value.filter((item) => activeRecord.value._id === item._id)[0],
              activeRecord.value,
            )
            console.log(dataSource.value)
          })
          .catch(function () {
            message.error("Impossible de télécharger l'image.")
          })
          .finally(() => {
            visibleModal.value = false
          })
      }
    }
    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
      }

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)
        fileList.value = [...fileList.value, file]
      }
      return isJpgOrPng && isLt2M
    }
    return {
      edit,
      save,
      visible,
      searchText: '',
      searchInput,
      columns,
      baseUrl,
      handleReset,
      handleSearch,
      suppPub,
      dataSource,
      editableData,
      tableLoading: false,
      changeAvatar,
      visibleModal,
      fileList,
      handleChange,
      beforeUpload,
      handleRemove,
      activeRecord,
      onSubmit,
      changeOrder,
      count,
    }
  },
}
</script>

<style lang="less" scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.ant-modal-body {
  height: 220px;
}
</style>
