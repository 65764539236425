<template>
  <a-modal
    v-model:visible="visibleModal"
    title="Ajouter membre a l'equipe"
    :width="700"
    ok-text="Ajouter"
    @ok="onSubmit"
    @cancel="cancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      label-align="left"
      class="container center"
      :rules="rules"
      :label-col="{ span: 10 }"
      :wrapper-col="{ span: 12 }"
      @finishFailed="handleFinishFailed"
    >
      <div class="row">
        <a-divider>Français</a-divider>
        <a-row>
          <a-col :span="12">
            <a-form-item label="Nom et prénom" name="fr.fullName">
              <a-input v-model:value="formState.fr.fullName" placeholder="Nom et prénom" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="poste" name="fr.post">
              <a-input v-model:value="formState.fr.post" placeholder="poste" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider>English</a-divider>
        <a-row>
          <a-col :span="12"
            ><a-form-item label="Fullname" name="en.fullName">
              <a-input v-model:value="formState.en.fullName" placeholder="Fullname" /> </a-form-item
          ></a-col>
          <a-col :span="12"
            ><a-form-item label="Job" name="en.post">
              <a-input v-model:value="formState.en.post" placeholder="Job" /> </a-form-item
          ></a-col>
        </a-row>
        <a-divider>العربية</a-divider>
        <a-row style="direction: RTL">
          <a-col :span="12">
            <a-form-item label="الاسم و اللقب" name="ar.fullName">
              <a-input
                v-model:value="formState.ar.fullName"
                placeholder="الاسم و اللقب"
              /> </a-form-item
          ></a-col>
          <a-col :span="12">
            <a-form-item label="مهنة" name="ar.post">
              <a-input v-model:value="formState.ar.post" placeholder="مهنة" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-upload-dragger
              :file-list="fileList"
              name="file"
              :multiple="false"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :custom-request="
                () => {
                  return true
                }
              "
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">Click or drag file to area to upload</p>
              <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data
                or other band files
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
/* eslint-disable */
import { ref, toRaw, watch, emits, reactive, computed } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue'

export default {
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  emits: ['addedUser', 'cancel'],
  props: {
    visible: {
      type: Boolean,
    },
  },
  setup(props) {
    const formRef = ref()
    const visibleModal = ref(false)

    watch(
      () => props.visible,
      (first, second) => {
        visibleModal.value = first
      },
    )
    const rules = {
      fullName: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
      post: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
    }
    const formState = reactive({
      fr: {
        fullName: '',
        post: '',
      },
      en: {
        fullName: '',
        post: '',
      },
      ar: {
        fullName: '',
        post: '',
      },
    })
    //file upload
    const fileList = ref([])
    const loading = ref(false)

    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
      }

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)
        fileList.value = [...fileList.value, file]
      }
      return isJpgOrPng && isLt2M
    }
    /* const onSubmit = () => {
      console.log(formRef.value)
      formRef.value
        .validate().then(() => {
          console.log("validated")
        })
    }*/
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }
    return {
      handleFinishFailed,
      visibleModal,
      formState,
      rules,
      formRef,
      fileList,
      loading,
      handleChange,
      beforeUpload,
      handleRemove,
    }
  },
  methods: {
    cancel(e) {
      this.$emit('cancel', false)
    },
    onSubmit() {
      this.formRef
        .validate()
        .then(() => {
          const data = toRaw(this.formState)
          ApiClient.put('/organigrame', {
            data: data,
          })
            .then((res) => {
              if (this.fileList.length > 0) {
                let formData = new FormData()
                formData.append('avatar', this.fileList[0], res.data._id)
                ApiClient.patch('/organigrame/files/' + res.data._id, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }).catch(function () {
                  message.error("Impossible de télécharger l'image.")
                })
              }

              this.$emit('memberAdded', res.data)
              message.success(`Membre ajouté`)
            })
            .catch((e) => {
              message.warning("Impossible d'ajouter les information saisis")
            })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
  },
}
</script>
